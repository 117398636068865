import {
    RESET_FIRST_STEP_STATE,
    SET_COTTAGE_EQUITY, SET_HOME_EQUITY,
    SET_INHERITANCE_AMOUNT_SPOUSE,
    SET_INHERITANCE_AMOUNT_YOU,
    SET_INHERITANCE_SPOUSE, SET_INHERITANCE_YEAR_SPOUSE, SET_INHERITANCE_YEAR_YOU,
    SET_INHERITANCE_YOU,
    SET_LOCKED_IN_LEGISLATION_TYPE_SPOUSE,
    SET_LOCKED_IN_LEGISLATION_TYPE_YOU,
    SET_LOCKED_IN_TOTAL_SPOUSE,
    SET_LOCKED_IN_TOTAL_YOU,
    SET_NON_REGISTERED_CONTRIBUTION_SPOUSE,
    SET_NON_REGISTERED_CONTRIBUTION_YOU,
    SET_NON_REGISTERED_TOTAL_SPOUSE,
    SET_NON_REGISTERED_TOTAL_YOU,
    SET_PROPERTIES,
    SET_RENTAL_EQUITY,
    SET_RRSP_CONTRIBUTE_SPOUSE,
    SET_RRSP_CONTRIBUTE_YOU,
    SET_RRSP_TOTAL_SPOUSE,
    SET_RRSP_TOTAL_YOU,
    SET_SAVINGS_OR_INVESTMENTS_SELECTOR_SPOUSE,
    SET_SAVINGS_OR_INVESTMENTS_SELECTOR_YOU,
    SET_TFSA_CONTRIBUTION_SPOUSE,
    SET_TFSA_CONTRIBUTION_YOU,
    SET_TFSA_TOTAL_SPOUSE,
    SET_TFSA_TOTAL_YOU, SET_THIRD_STEP_COMPLETED,
    SET_WORKPLACE_SAVINGS_CONTRIBUTION_SPOUSE,
    SET_WORKPLACE_SAVINGS_CONTRIBUTION_YOU,
    SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_SPOUSE,
    SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_YOU,
    SET_WORKPLACE_SAVINGS_TOTAL_SPOUSE,
    SET_WORKPLACE_SAVINGS_TOTAL_YOU
} from "../actions/actionTypes";
import savingsAndInvestmentsInitState from "../initStates/savingsAndInvestmentsInitState";

const initialStateLocalStorage = localStorage.getItem('savingsAndInvestments');

const initialState = initialStateLocalStorage
    ?
    JSON.parse(initialStateLocalStorage)
    :
    savingsAndInvestmentsInitState;

export default function savingsAndInvestmentsReducer(state = initialState, action) {

    switch (action.type ) {
        case RESET_FIRST_STEP_STATE:
            let stateBeforeReset = {...state};
            stateBeforeReset = savingsAndInvestmentsInitState;
            return stateBeforeReset;
        case SET_SAVINGS_OR_INVESTMENTS_SELECTOR_YOU:
            const savingsOrInvestmentsSelectorNewState = {...state};
            savingsOrInvestmentsSelectorNewState.you.rrsp.exist = action.data.rrspYou;
            if(!action.data.rrspYou){
                savingsOrInvestmentsSelectorNewState.you.rrsp.total = 0;
                savingsOrInvestmentsSelectorNewState.you.rrsp.contributeMonthly = 0;
            }
            savingsOrInvestmentsSelectorNewState.you.workplaceSavings.exist = action.data.workplaceSavingsYou;
            if(!action.data.workplaceSavingsYou){
                savingsOrInvestmentsSelectorNewState.you.workplaceSavings.total = 0;
                savingsOrInvestmentsSelectorNewState.you.workplaceSavings.contributeMonthly = 0;
                savingsOrInvestmentsSelectorNewState.you.workplaceSavings.legislationType = null;
            }
            savingsOrInvestmentsSelectorNewState.you.lockedIn.exist = action.data.lockedInYou;
            if(!action.data.lockedInYou){
                savingsOrInvestmentsSelectorNewState.you.lockedIn.total = 0;
                savingsOrInvestmentsSelectorNewState.you.lockedIn.legislationType = null;
            }
            savingsOrInvestmentsSelectorNewState.you.tfsa.exist = action.data.tfsaYou;
            if(!action.data.tfsaYou){
                savingsOrInvestmentsSelectorNewState.you.tfsa.total = 0;
                savingsOrInvestmentsSelectorNewState.you.tfsa.contributeMonthly = 0;
            }
            savingsOrInvestmentsSelectorNewState.you.nonRegistered.exist = action.data.nonRegisteredYou;
            if(!action.data.nonRegisteredYou){
                savingsOrInvestmentsSelectorNewState.you.nonRegistered.total = 0;
                savingsOrInvestmentsSelectorNewState.you.nonRegistered.contributeMonthly = 0;
            }
            return savingsOrInvestmentsSelectorNewState;
        case SET_SAVINGS_OR_INVESTMENTS_SELECTOR_SPOUSE:
            const savingsOrInvestmentsSelectorNewStateSpouse = {...state};
            savingsOrInvestmentsSelectorNewStateSpouse.spouse.rrsp.exist = action.data.rrspYou;
            if(!action.data.rrspYou){
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.rrsp.total = 0;
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.rrsp.contributeMonthly = 0;
            }
            savingsOrInvestmentsSelectorNewStateSpouse.spouse.workplaceSavings.exist = action.data.workplaceSavingsYou;
            if(!action.data.workplaceSavingsYou){
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.workplaceSavings.total = 0;
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.workplaceSavings.contributeMonthly = 0;
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.workplaceSavings.legislationType = null;
            }
            savingsOrInvestmentsSelectorNewStateSpouse.spouse.lockedIn.exist = action.data.lockedInYou;
            if(!action.data.lockedInYou){
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.lockedIn.total = 0;
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.lockedIn.legislationType = null;
            }
            savingsOrInvestmentsSelectorNewStateSpouse.spouse.tfsa.exist = action.data.tfsaYou;
            if(!action.data.tfsaYou){
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.tfsa.total = 0;
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.tfsa.contributeMonthly = 0;
            }
            savingsOrInvestmentsSelectorNewStateSpouse.spouse.nonRegistered.exist = action.data.nonRegisteredYou;
            if(!action.data.nonRegisteredYou){
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.nonRegistered.total = 0;
                savingsOrInvestmentsSelectorNewStateSpouse.spouse.nonRegistered.contributeMonthly = 0;
            }
            return savingsOrInvestmentsSelectorNewStateSpouse;
        case SET_RRSP_TOTAL_YOU:
            const stateRrpsTotalYou = {...state};
            stateRrpsTotalYou.you.rrsp.total = action.val;
            return stateRrpsTotalYou;
        case SET_RRSP_TOTAL_SPOUSE:
            const stateRrpsTotalSpouse = {...state};
            stateRrpsTotalSpouse.spouse.rrsp.total = action.val;
            return stateRrpsTotalSpouse;
        case SET_RRSP_CONTRIBUTE_YOU:
            const stateRrpsContributeYou = {...state};
            stateRrpsContributeYou.you.rrsp.contributeMonthly = action.val;
            return stateRrpsContributeYou;
        case SET_RRSP_CONTRIBUTE_SPOUSE:
            const stateRrpsContributeSpouse = {...state};
            stateRrpsContributeSpouse.spouse.rrsp.contributeMonthly = action.val;
            return stateRrpsContributeSpouse;
        case SET_WORKPLACE_SAVINGS_TOTAL_YOU:
            const stateWorkplaceSavingsTotalYou = {...state};
            stateWorkplaceSavingsTotalYou.you.workplaceSavings.total = action.val;
            return stateWorkplaceSavingsTotalYou;
        case SET_WORKPLACE_SAVINGS_TOTAL_SPOUSE:
            const stateWorkplaceSavingsTotalSpouse = {...state};
            stateWorkplaceSavingsTotalSpouse.spouse.workplaceSavings.total = action.val;
            return stateWorkplaceSavingsTotalSpouse;
        case SET_WORKPLACE_SAVINGS_CONTRIBUTION_YOU:
            const stateWorkplaceSavingsContributeYou = {...state};
            stateWorkplaceSavingsContributeYou.you.workplaceSavings.contributeMonthly = action.val;
            return stateWorkplaceSavingsContributeYou;
        case SET_WORKPLACE_SAVINGS_CONTRIBUTION_SPOUSE:
            const stateWorkplaceSavingsContributeSpouse = {...state};
            stateWorkplaceSavingsContributeSpouse.spouse.workplaceSavings.contributeMonthly = action.val;
            return stateWorkplaceSavingsContributeSpouse;
        case SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_YOU:
            const stateWorkplaceSavingsLegislationTypeYou = {...state};
            stateWorkplaceSavingsLegislationTypeYou.you.workplaceSavings.legislationType = action.val;
            return stateWorkplaceSavingsLegislationTypeYou;
        case SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_SPOUSE:
            const stateWorkplaceSavingsLegislationTypeSpouse = {...state};
            stateWorkplaceSavingsLegislationTypeSpouse.spouse.workplaceSavings.legislationType = action.val;
            return stateWorkplaceSavingsLegislationTypeSpouse;
        case SET_LOCKED_IN_TOTAL_YOU:
            const stateLockedInTotalYou = {...state};
            stateLockedInTotalYou.you.lockedIn.total = action.val;
            return stateLockedInTotalYou;
        case SET_LOCKED_IN_TOTAL_SPOUSE:
            const stateLockedInTotalSpouse = {...state};
            stateLockedInTotalSpouse.spouse.lockedIn.total = action.val;
            return stateLockedInTotalSpouse;
        case SET_LOCKED_IN_LEGISLATION_TYPE_YOU:
            const stateLockedInLegislationTypeYou = {...state};
            stateLockedInLegislationTypeYou.you.lockedIn.legislationType = action.val;
            return stateLockedInLegislationTypeYou;
        case SET_LOCKED_IN_LEGISLATION_TYPE_SPOUSE:
            const stateLockedInLegislationTypeSpouse = {...state};
            stateLockedInLegislationTypeSpouse.spouse.lockedIn.legislationType = action.val;
            return stateLockedInLegislationTypeSpouse;
        case SET_TFSA_TOTAL_YOU:
            const stateTfsaTotalYou = {...state};
            stateTfsaTotalYou.you.tfsa.total = action.val;
            return stateTfsaTotalYou;
        case SET_TFSA_TOTAL_SPOUSE:
            const stateTfsaTotalSpouse = {...state};
            stateTfsaTotalSpouse.spouse.tfsa.total = action.val;
            return stateTfsaTotalSpouse;
        case SET_TFSA_CONTRIBUTION_YOU:
            const stateTfsaContributeYou = {...state};
            stateTfsaContributeYou.you.tfsa.contributeMonthly = action.val;
            return stateTfsaContributeYou;
        case SET_TFSA_CONTRIBUTION_SPOUSE:
            const stateTfsaContributeSpouse = {...state};
            stateTfsaContributeSpouse.spouse.tfsa.contributeMonthly = action.val;
            return stateTfsaContributeSpouse;
        case SET_NON_REGISTERED_TOTAL_YOU:
            const stateNonRegisteredTotalYou = {...state};
            stateNonRegisteredTotalYou.you.nonRegistered.total = action.val;
            return stateNonRegisteredTotalYou;
        case SET_NON_REGISTERED_TOTAL_SPOUSE:
            const stateNonRegisteredTotalSpouse = {...state};
            stateNonRegisteredTotalSpouse.spouse.nonRegistered.total = action.val;
            return stateNonRegisteredTotalSpouse;
        case SET_NON_REGISTERED_CONTRIBUTION_YOU:
            const stateNonRegisteredContributeYou = {...state};
            stateNonRegisteredContributeYou.you.nonRegistered.contributeMonthly = action.val;
            return stateNonRegisteredContributeYou;
        case SET_NON_REGISTERED_CONTRIBUTION_SPOUSE:
            const stateNonRegisteredContributeSpouse = {...state};
            stateNonRegisteredContributeSpouse.spouse.nonRegistered.contributeMonthly = action.val;
            return stateNonRegisteredContributeSpouse;
        case SET_PROPERTIES:
            const propertiesState = {...state};
            propertiesState.properties.exist = !action.data.no;
            propertiesState.properties.isCottage = action.data.cottage;
            propertiesState.properties.isRentalProperties = action.data.rental;
            if(action.data.no) {
                propertiesState.properties.isCottage = false;
                propertiesState.properties.isRentalProperties = false;
            }
            return propertiesState;
        case SET_COTTAGE_EQUITY:
            const stateCottageEquity = {...state};
            stateCottageEquity.properties.cottages = action.val;
            return stateCottageEquity;
        case SET_RENTAL_EQUITY:
            const stateRentalEquity = {...state};
            stateRentalEquity.properties.rentalProperties = action.val;
            return stateRentalEquity;
        case SET_HOME_EQUITY:
            const stateHomeEquity = {...state};
            stateHomeEquity.properties.home = action.val;
            return stateHomeEquity;
        case SET_INHERITANCE_YOU:
            const stateInheritanceYou = {...state};
            stateInheritanceYou.you.inheritance.exist = action.val === 'Yes';
            if(action.val === 'No'){
                stateInheritanceYou.you.inheritance.total = 0;
            }
            return stateInheritanceYou;
        case SET_INHERITANCE_SPOUSE:
            const stateInheritanceSpouse = {...state};
            stateInheritanceSpouse.spouse.inheritance.exist = action.val === 'Yes';
            if(action.val === 'No'){
                stateInheritanceSpouse.spouse.inheritance.total = 0;
            }
            return stateInheritanceSpouse;
        case SET_INHERITANCE_AMOUNT_YOU:
            const stateInheritanceAmountYou = {...state};
            stateInheritanceAmountYou.you.inheritance.total = action.val;
            return stateInheritanceAmountYou;
        case SET_INHERITANCE_AMOUNT_SPOUSE:
            const stateInheritanceAmountSpouse = {...state};
            stateInheritanceAmountSpouse.spouse.inheritance.total = action.val;
            return stateInheritanceAmountSpouse;
        case SET_INHERITANCE_YEAR_YOU:
            const stateInheritanceYearYou = {...state};
            stateInheritanceYearYou.you.inheritance.inheritanceYear = action.val;
            return stateInheritanceYearYou;
        case SET_INHERITANCE_YEAR_SPOUSE:
            const stateInheritanceYearSpouse = {...state};
            stateInheritanceYearSpouse.spouse.inheritance.inheritanceYear = action.val;
            return stateInheritanceYearSpouse;
        case SET_THIRD_STEP_COMPLETED:
            return {
                ...state,
                thirdStepCompleted : action.val
            }
        default:
            return state
    }
}