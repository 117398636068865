import React from 'react';
import classes from './Loading.module.scss'

const Loading = () => (
    <div className={classes.loadingWrap}>
        <div className = {classes.Loading}>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
);

export default Loading;