/*ABOUT YOU*/

export const SET_I_AM_VAL = 'SET_I_AM_VAL';
export const SET_I_AM_YEARS_OLD = 'SET_I_AM_YEARS_OLD';

export const FETCH_RADIO_ERROR = 'FETCH_RADIO_ERROR';

export const UPDATE_MY_FAMILY_DATA= 'UPDATE_MY_FAMILY_DATA';
export const SET_SEX_SPOUSE= 'SET_SEX_SPOUSE';
export const SET_SPOUSE_YEARS_OLD= 'SET_SPOUSE_YEARS_OLD';

export const SET_CHILDREN= 'SET_CHILDREN';
export const SET_CHILD_YEARS_OLD= 'SET_CHILD_YEARS_OLD';

export const SET_DEPENDANTS= 'SET_DEPENDANTS';
export const SET_DEPENDANT_YEARS_OLD= 'SET_DEPENDANT_YEARS_OLD';
export const IS_COMPLETED= 'IS_COMPLETED';

export const SET_RETIRE_AGE= 'SET_RETIRE_AGE';
export const SET_SPOUSE_RETIRE_AGE= 'SET_SPOUSE_RETIRE_AGE';
export const SET_YEARS_INCOME= 'SET_YEARS_INCOME';

export const SET_RENT_OR_OWNER= 'SET_RENT_OR_OWNER';
export const SET_POSTAL_CODE= 'SET_POSTAL_CODE';
export const SET_ANTICIPATED_INCOME= 'SET_ANTICIPATED_INCOME';
export const SET_INFLATION= 'SET_INFLATION';

export const SET_ABOUT_YOU_SUM_RETIRE_AGE_ERROR= 'SET_ABOUT_YOU_SUM_RETIRE_AGE_ERROR';
export const SET_ABOUT_YOU_SUM_COMPLETED= 'SET_ABOUT_YOU_SUM_COMPLETED';

export const RESET_FIRST_STEP_STATE= 'RESET_FIRST_STEP_STATE';

/*INVESTOR PROFILE*/

export const SET_SHORT_TERM_LOSSES_YOU= 'SET_SHORT_TERM_LOSSES_YOU';
export const SET_SHORT_TERM_LOSSES_SPOUSE= 'SET_SHORT_TERM_LOSSES_SPOUSE';
export const RESET_LONG_TERM_RECOVERY_PERIOD= 'RESET_LONG_TERM_RECOVERY_PERIOD';
export const SET_IS_COMPLETED_SHORT_TERM_LOSSES= 'SET_IS_COMPLETED_SHORT_TERM_LOSSES';

export const SET_LONG_TERM_RETURNS_YOU= 'SET_LONG_TERM_RETURNS_YOU';
export const SET_LONG_TERM_RETURNS_SPOUSE= 'SET_LONG_TERM_RETURNS_SPOUSE';
export const SET_IS_COMPLETED_LONG_TERM_RETURNS= 'SET_IS_COMPLETED_LONG_TERM_RETURNS';

export const SET_RECOVERY_PERIOD_YOU= 'SET_RECOVERY_PERIOD_YOU';
export const SET_RECOVERY_PERIOD_SPOUSE= 'SET_RECOVERY_PERIOD_SPOUSE';

export const SET_GUARANTEED_FEE_YOU= 'SET_GUARANTEED_FEE_YOU';
export const SET_GUARANTEED_FEE_SPOUSE= 'SET_GUARANTEED_FEE_SPOUSE';
export const SET_GUARANTEED_FEE= 'SET_GUARANTEED_FEE';

export const SET_GUARANTEED_PERCENTAGE_YOU= 'SET_GUARANTEED_PERCENTAGE_YOU';
export const SET_GUARANTEED_PERCENTAGE_SPOUSE= 'SET_GUARANTEED_PERCENTAGE_SPOUSE';

export const SET_RATE_OF_RETURN_YOU= 'SET_RATE_OF_RETURN_YOU';
export const SET_RATE_OF_RETURN_SPOUSE= 'SET_RATE_OF_RETURN_SPOUSE';

export const SET_PROFILE= 'SET_PROFILE';
export const SET_SECOND_STEP_COMPLETED= 'SET_SECOND_STEP_COMPLETED';

export const RESET_SECOND_STEP_STATE= 'RESET_SECOND_STEP_STATE';

/*YOUR SAVINGS & INVESTMENTS*/

export const RESET_THIRD_STEP_STATE= 'RESET_THIRD_STEP_STATE';
export const SET_SAVINGS_OR_INVESTMENTS_SELECTOR_YOU= 'SET_SAVINGS_OR_INVESTMENTS_SELECTOR_YOU';
export const SET_SAVINGS_OR_INVESTMENTS_SELECTOR_SPOUSE= 'SET_SAVINGS_OR_INVESTMENTS_SELECTOR_SPOUSE';

export const SET_RRSP_TOTAL_YOU= 'SET_RRSP_TOTAL_YOU';
export const SET_RRSP_TOTAL_SPOUSE= 'SET_RRSP_TOTAL_SPOUSE';
export const SET_RRSP_CONTRIBUTE_YOU= 'SET_RRSP_CONTRIBUTE_YOU';
export const SET_RRSP_CONTRIBUTE_SPOUSE= 'SET_RRSP_CONTRIBUTE_SPOUSE';

export const SET_WORKPLACE_SAVINGS_TOTAL_YOU= 'SET_WORKPLACE_SAVINGS_TOTAL_YOU';
export const SET_WORKPLACE_SAVINGS_TOTAL_SPOUSE= 'SET_WORKPLACE_SAVINGS_TOTAL_SPOUSE';
export const SET_WORKPLACE_SAVINGS_CONTRIBUTION_YOU= 'SET_WORKPLACE_SAVINGS_CONTRIBUTION_YOU';
export const SET_WORKPLACE_SAVINGS_CONTRIBUTION_SPOUSE= 'SET_WORKPLACE_SAVINGS_CONTRIBUTION_SPOUSE';
export const SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_YOU= 'SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_YOU';
export const SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_SPOUSE= 'SET_WORKPLACE_SAVINGS_LEGISLATION_TYPE_SPOUSE';

export const SET_LOCKED_IN_TOTAL_YOU= 'SET_LOCKED_IN_TOTAL_YOU';
export const SET_LOCKED_IN_TOTAL_SPOUSE= 'SET_LOCKED_IN_TOTAL_SPOUSE';
export const SET_LOCKED_IN_LEGISLATION_TYPE_YOU= 'SET_LOCKED_IN_LEGISLATION_TYPE_YOU';
export const SET_LOCKED_IN_LEGISLATION_TYPE_SPOUSE= 'SET_LOCKED_IN_LEGISLATION_TYPE_SPOUSE';

export const SET_TFSA_TOTAL_YOU= 'SET_TFSA_TOTAL_YOU';
export const SET_TFSA_TOTAL_SPOUSE= 'SET_TFSA_TOTAL_SPOUSE';
export const SET_TFSA_CONTRIBUTION_YOU= 'SET_TFSA_CONTRIBUTION_YOU';
export const SET_TFSA_CONTRIBUTION_SPOUSE= 'SET_TFSA_CONTRIBUTION_SPOUSE';


export const SET_NON_REGISTERED_TOTAL_YOU= 'SET_NON_REGISTERED_TOTAL_YOU';
export const SET_NON_REGISTERED_TOTAL_SPOUSE= 'SET_NON_REGISTERED_TOTAL_SPOUSE';
export const SET_NON_REGISTERED_CONTRIBUTION_YOU= 'SET_NON_REGISTERED_CONTRIBUTION_YOU';
export const SET_NON_REGISTERED_CONTRIBUTION_SPOUSE= 'SET_NON_REGISTERED_CONTRIBUTION_SPOUSE';

export const SET_PROPERTIES= 'SET_PROPERTIES';
export const SET_COTTAGE_EQUITY= 'SET_COTTAGE_EQUITY';
export const SET_RENTAL_EQUITY= 'SET_RENTAL_EQUITY';

export const SET_HOME_EQUITY= 'SET_HOME_EQUITY';

export const SET_INHERITANCE_YOU= 'SET_INHERITANCE_YOU';
export const SET_INHERITANCE_SPOUSE= 'SET_INHERITANCE_SPOUSE';
export const SET_INHERITANCE_AMOUNT_YOU= 'SET_INHERITANCE_AMOUNT_YOU';
export const SET_INHERITANCE_AMOUNT_SPOUSE= 'SET_INHERITANCE_AMOUNT_SPOUSE';
export const SET_INHERITANCE_YEAR_YOU= 'SET_INHERITANCE_YEAR_YOU';
export const SET_INHERITANCE_YEAR_SPOUSE= 'SET_INHERITANCE_YEAR_SPOUSE';

export const SET_THIRD_STEP_COMPLETED= 'SET_THIRD_STEP_COMPLETED';

/*YOUR SOURCES OF INCOME*/
export const SET_OTHER_SOURCES_SELECTOR_YOU= 'SET_OTHER_SOURCES_SELECTOR_YOU';
export const SET_OTHER_SOURCES_SELECTOR_SPOUSE= 'SET_OTHER_SOURCES_SELECTOR_SPOUSE';
export const SET_CPP_YOU= 'SET_CPP_YOU';
export const SET_CPP_SPOUSE= 'SET_CPP_SPOUSE';
export const SET_QPP_YOU= 'SET_QPP_YOU';
export const SET_QPP_SPOUSE= 'SET_QPP_SPOUSE';
export const SET_OAS_PENSION_YOU= 'SET_OAS_PENSION_YOU';
export const SET_OAS_PENSION_SPOUSE= 'SET_OAS_PENSION_SPOUSE';
export const SET_EMPLOYER_PENSION_YOU= 'SET_EMPLOYER_PENSION_YOU';
export const SET_EMPLOYER_PENSION_SPOUSE= 'SET_EMPLOYER_PENSION_SPOUSE';
export const SET_OTHER_YOU= 'SET_OTHER_YOU';
export const SET_OTHER_SPOUSE= 'SET_OTHER_SPOUSE';
export const SET_FOURTH_STEP_COMPLETED= 'SET_FOURTH_STEP_COMPLETED';

