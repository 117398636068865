import
{
    SET_SHORT_TERM_LOSSES_YOU,
    SET_SHORT_TERM_LOSSES_SPOUSE,
    SET_IS_COMPLETED_SHORT_TERM_LOSSES,
    SET_LONG_TERM_RETURNS_YOU,
    SET_LONG_TERM_RETURNS_SPOUSE,
    SET_IS_COMPLETED_LONG_TERM_RETURNS,
    RESET_LONG_TERM_RECOVERY_PERIOD,
    SET_RECOVERY_PERIOD_YOU,
    SET_RECOVERY_PERIOD_SPOUSE,
    SET_GUARANTEED_FEE_SPOUSE,
    SET_GUARANTEED_FEE_YOU, SET_GUARANTEED_FEE, SET_GUARANTEED_PERCENTAGE_YOU,
    SET_GUARANTEED_PERCENTAGE_SPOUSE,
    SET_RATE_OF_RETURN_YOU,
    SET_RATE_OF_RETURN_SPOUSE,
    SET_PROFILE, SET_SECOND_STEP_COMPLETED, RESET_SECOND_STEP_STATE,
} from "../actions/actionTypes";

import investorProfileInitState from "../initStates/investorProfileInitState";

const localStorageStateIncomeProfile = localStorage.getItem('investorProfile')
const initialState = localStorageStateIncomeProfile
    ?
    JSON.parse(localStorageStateIncomeProfile)
    :
    investorProfileInitState

export default function investorProfileReducer(state = initialState, action) {
    switch (action.type ) {
        case SET_SHORT_TERM_LOSSES_YOU:
            if (action.val === 'No' ) {
                return {
                    ...state,
                    shortTermLossesYou: action.val,
                    longTermReturnsYou: null,
                    recoveryPeriodYou: 3,
                }
            }
             return {
             ...state,
             shortTermLossesYou: action.val
             }
         case SET_SHORT_TERM_LOSSES_SPOUSE:
             if(action.val === 'No') {
                 return {
                     ...state,
                     shortTermLossesSpouse: action.val,
                     longTermReturnsSpouse: null,
                     recoveryPeriodSpouse: 3,
                 }
             }
             return {
             ...state,
             shortTermLossesSpouse: action.val
             }
        case SET_IS_COMPLETED_SHORT_TERM_LOSSES:
             const isCompletedShortTermLossesState = {...state}
            isCompletedShortTermLossesState.isCompletedShortTermLosses = false
            if(action.single) {
                if(isCompletedShortTermLossesState.shortTermLossesYou){
                    isCompletedShortTermLossesState.isCompletedShortTermLosses = true
                }
            }else {
                if(isCompletedShortTermLossesState.shortTermLossesYou && isCompletedShortTermLossesState.shortTermLossesSpouse){
                    isCompletedShortTermLossesState.isCompletedShortTermLosses = true
                }
            }
             return isCompletedShortTermLossesState
        case RESET_LONG_TERM_RECOVERY_PERIOD:
            return {
                ...state,
                longTermReturnsYou: null,
                longTermReturnsSpouse: null,
                isCompletedLongTermReturns: false,
                recoveryPeriodYou: 3,
                recoveryPeriodSpouse: 3,
            }
        case SET_LONG_TERM_RETURNS_YOU:
            if(action.val === 'No') {
                return {
                    ...state,
                    longTermReturnsYou: action.val,
                    recoveryPeriodYou: 3,
                }
            }
            return {
                ...state,
                longTermReturnsYou: action.val
            }
        case SET_LONG_TERM_RETURNS_SPOUSE:
            if(action.val === 'No') {
                return {
                    ...state,
                    longTermReturnsSpouse: action.val,
                    recoveryPeriodSpouse: 3,
                }
            }
            return {
                ...state,
                longTermReturnsSpouse: action.val
            }
        case SET_IS_COMPLETED_LONG_TERM_RETURNS:
            const isCompletedLongTermReturnsState = {...state}
            isCompletedLongTermReturnsState.isCompletedLongTermReturns = false

            if(
                (action.single && isCompletedLongTermReturnsState.longTermReturnsYou)
            ||
                (isCompletedLongTermReturnsState.longTermReturnsYou && isCompletedLongTermReturnsState.longTermReturnsSpouse)
            ) {
                    isCompletedLongTermReturnsState.isCompletedLongTermReturns = true
            }
            return isCompletedLongTermReturnsState
        case SET_RECOVERY_PERIOD_YOU:
            return {
                ...state,
                recoveryPeriodYou: action.val
            }
        case SET_RECOVERY_PERIOD_SPOUSE:
            return {
                ...state,
                recoveryPeriodSpouse: action.val
            }
        case SET_GUARANTEED_FEE_YOU:
            const copyState = {...state}
            if(action.val === 'No') {
                copyState.guaranteedPercentageYou = 50
            }
            copyState.guaranteedFeeYou = action.val
            return copyState
        case SET_GUARANTEED_FEE_SPOUSE:
            const newState = {...state}
            if(action.val === 'No') {
                newState.guaranteedPercentageSpouse = 50
            }
            newState.guaranteedFeeSpouse = action.val
            return newState
        case SET_GUARANTEED_FEE:
            const isCompletedGuaranteedFee = {...state}
            isCompletedGuaranteedFee.isCompletedGuaranteedFee = false
            if(
                (action.single && isCompletedGuaranteedFee.guaranteedFeeYou)
                ||
                (isCompletedGuaranteedFee.guaranteedFeeYou && isCompletedGuaranteedFee.guaranteedFeeSpouse)
            ) {
                isCompletedGuaranteedFee.isCompletedGuaranteedFee = true
            }
            return isCompletedGuaranteedFee
        case SET_GUARANTEED_PERCENTAGE_YOU:
            return {
                ...state,
                guaranteedPercentageYou: action.val
            }
        case SET_GUARANTEED_PERCENTAGE_SPOUSE:
            return {
                ...state,
                guaranteedPercentageSpouse: action.val
            }
        case SET_RATE_OF_RETURN_YOU:
            return {
                ...state,
                rateOfReturnYou: action.val
            }
        case SET_RATE_OF_RETURN_SPOUSE:
            return {
                ...state,
                rateOfReturnSpouse: action.val
            }
        case SET_PROFILE:
            if(action.person === 'you'){
                return {
                    ...state,
                    profileYou: action.val
                }
            }
            if(action.person === 'spouse'){
                return {
                    ...state,
                    profileSpouse: action.val
                }
            }
            break
        case SET_SECOND_STEP_COMPLETED:
            return {
                ...state,
                secondStepCompleted: action.val
            };
        case RESET_SECOND_STEP_STATE:
            //const resetState = investorProfileInitState
            //return investorProfileInitState
            return {
                ...state,
            };
        default:
            return state
    }
}
