import {combineReducers} from "redux";
import aboutYouReducer from "./aboutYou";
import investorProfileReducer from "./investorProfileReducer";
import savingsAndInvestmentsReducer from "./savingsAndInvestmentsReducer";
import sourceOfIncomeReducer from "./SourceOfIncomeReducer";

export default combineReducers({
    aboutYou: aboutYouReducer,
    investorProfile: investorProfileReducer,
    savingsAndInvestments: savingsAndInvestmentsReducer,
    sourceOfIncome: sourceOfIncomeReducer,
                               })