import {
    SET_I_AM_VAL,
    SET_I_AM_YEARS_OLD,
    FETCH_RADIO_ERROR,
    UPDATE_MY_FAMILY_DATA,
    SET_SEX_SPOUSE,
    SET_SPOUSE_YEARS_OLD,
    SET_CHILDREN,
    SET_CHILD_YEARS_OLD,
    SET_DEPENDANTS,
    SET_DEPENDANT_YEARS_OLD,
    IS_COMPLETED,
    SET_RETIRE_AGE,
    SET_YEARS_INCOME,
    SET_RENT_OR_OWNER,
    SET_POSTAL_CODE,
    SET_ANTICIPATED_INCOME,
    SET_INFLATION,
    SET_SPOUSE_RETIRE_AGE,
    SET_ABOUT_YOU_SUM_RETIRE_AGE_ERROR,
    SET_ABOUT_YOU_SUM_COMPLETED,
    RESET_FIRST_STEP_STATE
} from "../actions/actionTypes";
import AboutYouInitState from '../initStates/aboutYouInitState'

const initialStateLocalStorage = localStorage.getItem('aboutYou')

const initialState = initialStateLocalStorage
    ?
    JSON.parse(initialStateLocalStorage)
    :
    AboutYouInitState

export default function aboutYouReducer(state = initialState, action) {

    switch (action.type ) {
        case SET_I_AM_VAL:
            return {
                ...state,
                iAm: action.val
            }
        case SET_I_AM_YEARS_OLD:
            return {
                ...state,
                iAmYearsOld: action.val
            }
        case FETCH_RADIO_ERROR:
            return {
                ...state,
                error : action.error
            }
        case UPDATE_MY_FAMILY_DATA:
            const myFamilyNewState = {...state}
            myFamilyNewState.myFamily.me = action.data.me
            myFamilyNewState.myFamily.spouseData.isSpouse = action.data.spouse
            myFamilyNewState.myFamily.childrenData.isChildren = action.data.children
            myFamilyNewState.myFamily.dependantsData.isDependants = action.data.dependants
            return myFamilyNewState
        case SET_SEX_SPOUSE:
            const setSexSpouseState = {...state}
            setSexSpouseState.myFamily.spouseData.gender = action.val
            return setSexSpouseState
        case SET_SPOUSE_YEARS_OLD:
            const setSpouseYeatsOldState = {...state}
            setSpouseYeatsOldState.myFamily.spouseData.yearsOld = action.val
            return setSpouseYeatsOldState
        case SET_CHILDREN:
            const setChildrenCountState = {...state}
            setChildrenCountState.myFamily.childrenData.children.count = action.val
            return setChildrenCountState
        case SET_CHILD_YEARS_OLD:
            const setChildYearsOldState = {...state}
            setChildYearsOldState.myFamily.childrenData.children.years[action.key] = action.val
            return setChildYearsOldState
        case SET_DEPENDANTS:
            const setDependantsCountState = {...state}
            setDependantsCountState.myFamily.dependantsData.dependants.count = action.val
            return setDependantsCountState
        case SET_DEPENDANT_YEARS_OLD:
            const setDependantYearsOldState = {...state}
            setDependantYearsOldState.myFamily.dependantsData.dependants.years[action.key] = action.val
            return setDependantYearsOldState
        case IS_COMPLETED:
            let completed = true
            const stateCopy = {...state}
            if(stateCopy.iAm === ''
                ||
                (
                    !stateCopy.myFamily.me
                    && !stateCopy.myFamily.spouseData.isSpouse
                    && !stateCopy.myFamily.childrenData.isChildren
                    && !stateCopy.myFamily.dependantsData.dependants.count
                )
                ||
                (
                    stateCopy.myFamily.spouseData.isSpouse
                    && !stateCopy.myFamily.spouseData.gender
                )
                ||
                (
                    stateCopy.myFamily.childrenData.isChildren
                    && !stateCopy.myFamily.childrenData.children.count >0
                )
                ||
                (
                    stateCopy.myFamily.dependantsData.isDependants
                    && !stateCopy.myFamily.dependantsData.dependants.count >0
                )
            )
            {
                completed = false
            }
            return {
                ...state,
                isCompleted : completed
            }
        case SET_RETIRE_AGE:
            return {
                ...state,
                retireAge : action.val
            }
        case SET_SPOUSE_RETIRE_AGE:
            return {
                ...state,
                spouseRetireAge : action.val
            }
        case SET_YEARS_INCOME:
            return {
                ...state,
                yearsIncome : action.yearsVal,
                averageLife : action.averageLife,
            }
        case SET_RENT_OR_OWNER:
            return {
                ...state,
                rentOrOwn : action.val,
            }
        case SET_POSTAL_CODE:
            return {
                ...state,
                postalCode : action.val,
            }
        case SET_ANTICIPATED_INCOME:
            return {
                ...state,
                anticipatedIncome : action.val,
            }
        case SET_INFLATION:
            return {
                ...state,
                inflation : action.val,
            }
        case SET_ABOUT_YOU_SUM_RETIRE_AGE_ERROR:
            return {
                ...state,
                errorAboutYouSumRetireAge : action.val,
            }
        case SET_ABOUT_YOU_SUM_COMPLETED:
            return {
                ...state,
                firstStepCompleted : !action.val,
            }
        case RESET_FIRST_STEP_STATE:
            let stateBeforeReset = {...state}
            stateBeforeReset = {
                iAm: '',
                iAmYearsOld: 40,
                myFamily: {
                    me: false,
                    spouseData: {
                        isSpouse: false,
                        gender: false,
                        yearsOld: 40
                    },
                    childrenData: {
                        isChildren: false,
                        children: {
                            count: 0,
                            years: [13,13,13,13,13,13]
                        },
                    },
                    dependantsData: {
                        isDependants: false,
                        dependants: {
                            count: 0,
                            years: []
                        },
                    }
                },
                retireAge: 65,
                spouseRetireAge: 65,
                averageLife: 84,
                yearsIncome: 19,
                rentOrOwn: null,
                postalCode: null,
                anticipatedIncome: 2400,
                inflation: 1.74,
                isCompleted: false,
                firstStepCompleted: false,
                errorAboutYouSumRetireAge: false

            }
            return stateBeforeReset
        default:
            return state
    }
}