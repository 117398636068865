export default {
    iAm: '',
    iAmYearsOld: 40,
    myFamily: {
        me: false,
        spouseData: {
            isSpouse: false,
            gender: false,
            yearsOld: 40
        },
        childrenData: {
            isChildren: false,
            children: {
                count: 0,
                years: [13,13,13,13,13,13]
            },
        },
        dependantsData: {
            isDependants: false,
            dependants: {
                count: 0,
                years: []
            },
        }
    },
    retireAge: 65,
    spouseRetireAge: 65,
    averageLife: 84,
    yearsIncome: 19,
    rentOrOwn: null,
    postalCode: null,
    anticipatedIncome: 2400,
    inflation: 1.74,
    isCompleted: false,
    firstStepCompleted: false,
    errorAboutYouSumRetireAge: false

}