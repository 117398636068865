export  default {
    shortTermLossesYou: null,
    shortTermLossesSpouse: null,
    isCompletedShortTermLosses: false,
    longTermReturnsYou: null,
    longTermReturnsSpouse: null,
    isCompletedLongTermReturns: false,
    recoveryPeriodYou: 3,
    recoveryPeriodSpouse: 3,
    guaranteedFeeYou: null,
    guaranteedFeeSpouse: null,
    isCompletedGuaranteedFee: false,
    guaranteedPercentageYou: 50,
    guaranteedPercentageSpouse: 50,
    rateOfReturnYou: 7.20,
    rateOfReturnSpouse: 6.57,
    profileYou: 'Balanced',
    profileSpouse: 'Balanced',
    secondStepCompleted: false
}