//const homeLink = window.location.pathname;
const homeLink = '/retirement-calculator/';
//const homeLink = '/';
const retirementCalcLinks = {
    aboutYou: homeLink + 'about-you',
    retireAge: homeLink + 'retire-age',
    yearsOfIncome: homeLink + 'years-of-income',
    rentOrOwn: homeLink + 'rent-or-own',
    anticipatedIncome: homeLink + 'anticipated-income',
    inflation: homeLink + 'inflation',
    aboutYouSummary: homeLink + 'about-you-summary',
    investorProfile: homeLink + 'investor-profile',
    shortTermLosses: homeLink + 'short-term-losses',
    longTermReturns: homeLink + 'long-term-returns',
    recoveryPeriod: homeLink + 'recovery-period',
    guaranteedFee: homeLink + 'guaranteed-fee',
    guaranteedPercentage: homeLink + 'guaranteed-percentage',
    profile: homeLink + 'profile',
    rateOfReturnYou: homeLink + 'rate-of-return-you',
    rateOfReturnSpouse: homeLink + 'rate-of-return-spouse',
    investorProfileSummary: homeLink + 'investor-profile-summary',
    savingsAndInvestments: homeLink + 'savings-and-investments',
    savingsOrInvestmentsSelector: homeLink + 'savings-or-investments-selector',
    rrspHave: homeLink + 'rrsp-have',
    rrspContribute: homeLink + 'rrsp-contribute',
    workplaceSavingsHave: homeLink + 'workplace-savings-have',
    workplaceSavingsContribute: homeLink + 'workplace-savings-contribute',
    workplaceSavingsLegislation: homeLink + 'workplace-savings-legislation',
    lockedInHave: homeLink + 'locked-in-have',
    lockedInLegislation: homeLink + 'locked-in-legislation',
    tfsaHave: homeLink + 'tfsa-have',
    tfsaContribute: homeLink + 'tfsa-contribute',
    nonRegisteredHave: homeLink + 'non-registered-have',
    nonRegisteredContribute: homeLink + 'non-registered-contribute',
    properties: homeLink + 'properties',
    homeEquity: homeLink + 'home-equity',
    cottageEquity: homeLink + 'cottage-equity',
    rentalEquity: homeLink + 'rental-equity',
    inheritance: homeLink + 'inheritance',
    inheritanceAmount: homeLink + 'inheritance-amount',
    inheritanceYear: homeLink + 'inheritance-year',
    savingsAndInvestmentsSummary: homeLink + 'savings-and-investments-summary',
    sourceOfIncome: homeLink + 'source-of-income',
    otherSourcesSelector: homeLink + 'other-sources-selector',
    cppAmount: homeLink + 'cpp-amount',
    qppAmount: homeLink + 'qpp-amount',
    oasPension: homeLink + 'oas-pension',
    employerPensionAmount: homeLink + 'employer-pension-amount',
    otherAmount: homeLink + 'other-amount',
    sourceOfIncomeSummary: homeLink + 'source-of-income-summary',
    retirementResults: homeLink + 'retirement-results',
};

export {homeLink, retirementCalcLinks}