export default {
    you: {
        cpp : {
            exist: false,
            val: 0
        },
        qpp: {
            exist: false,
            val: 0
        },
        oasPension: {
            exist: false,
            val: 0
        },
        employerPension: {
            exist: false,
            val: 0
        },
        other: {
            exist: false,
            val: 0
        }
    },
    spouse: {
        cpp : {
            exist: false,
            val: 0
        },
        qpp: {
            exist: false,
            val: 0
        },
        oasPension: {
            exist: false,
            val: 0
        },
        employerPension: {
            exist: false,
            val: 0
        },
        other: {
            exist: false,
            val: 0
        }
    },
    fourthStepCompleted: false
}