import SourceOfIncomeInitState from "../initStates/SourceOfIncomeInitState";
import {
    SET_FOURTH_STEP_COMPLETED,
    SET_OTHER_SOURCES_SELECTOR_YOU,
    SET_OTHER_SOURCES_SELECTOR_SPOUSE,
    SET_CPP_YOU,
    SET_CPP_SPOUSE,
    SET_QPP_YOU,
    SET_QPP_SPOUSE,
    SET_OAS_PENSION_YOU,
    SET_OAS_PENSION_SPOUSE,
    SET_EMPLOYER_PENSION_YOU,
    SET_EMPLOYER_PENSION_SPOUSE, SET_OTHER_YOU, SET_OTHER_SPOUSE
} from "../actions/actionTypes";


const initialStateLocalStorage = localStorage.getItem('sourceOfIncome');

const initialState = initialStateLocalStorage
    ?
    JSON.parse(initialStateLocalStorage)
    :
    SourceOfIncomeInitState;

export default function sourceOfIncomeReducer(state = initialState, action) {

    switch (action.type ) {
        case SET_OTHER_SOURCES_SELECTOR_YOU:
            const otherSourceSelectorNewState = {...state};
            otherSourceSelectorNewState.you.cpp.exist = action.data.cppYou;
            if(!action.data.cppYou){
                otherSourceSelectorNewState.you.cpp.val = 0;
            }
            otherSourceSelectorNewState.you.qpp.exist = action.data.qppYou;
            if(!action.data.qppYou){
                otherSourceSelectorNewState.you.qpp.val = 0;
            }
            otherSourceSelectorNewState.you.oasPension.exist = action.data.oasPensionYou;
            if(!action.data.oasPensionYou){
                otherSourceSelectorNewState.you.oasPension.val = 0;
            }
            otherSourceSelectorNewState.you.employerPension.exist = action.data.employerPensionYou;
            if(!action.data.employerPensionYou){
                otherSourceSelectorNewState.you.employerPension.val = 0;
            }
            otherSourceSelectorNewState.you.other.exist = action.data.otherYou;
            if(!action.data.otherYou){
                otherSourceSelectorNewState.you.other.val = 0;
            }
            return otherSourceSelectorNewState;
        case SET_OTHER_SOURCES_SELECTOR_SPOUSE:
            const otherSourceSelectorNewStateSpouse = {...state};
            otherSourceSelectorNewStateSpouse.spouse.cpp.exist = action.data.cppSpouse;
            if(!action.data.cppSpouse){
                otherSourceSelectorNewStateSpouse.spouse.cpp.val = 0;
            }
            otherSourceSelectorNewStateSpouse.spouse.qpp.exist = action.data.qppSpouse;
            if(!action.data.qppSpouse){
                otherSourceSelectorNewStateSpouse.spouse.qpp.val = 0;
            }
            otherSourceSelectorNewStateSpouse.spouse.oasPension.exist = action.data.oasPensionSpouse;
            if(!action.data.oasPensionSpouse){
                otherSourceSelectorNewStateSpouse.spouse.oasPension.val = 0;
            }
            otherSourceSelectorNewStateSpouse.spouse.employerPension.exist = action.data.employerPensionSpouse;
            if(!action.data.employerPensionYou){
                otherSourceSelectorNewStateSpouse.spouse.employerPension.val = 0;
            }
            otherSourceSelectorNewStateSpouse.spouse.other.exist = action.data.otherSpouse;
            if(!action.data.otherSpouse){
                otherSourceSelectorNewStateSpouse.spouse.other.val = 0;
            }
            return otherSourceSelectorNewStateSpouse;
        case SET_CPP_YOU:
            const stateCppYou = {...state};
            stateCppYou.you.cpp.val = action.val;
            return stateCppYou;
        case SET_CPP_SPOUSE:
            const stateCppSpouse = {...state};
            stateCppSpouse.spouse.cpp.val = action.val;
            return stateCppSpouse;
        case SET_QPP_YOU:
            const stateQppYou = {...state};
            stateQppYou.you.qpp.val = action.val;
            return stateQppYou;
        case SET_QPP_SPOUSE:
            const stateQppSpouse = {...state};
            stateQppSpouse.spouse.qpp.val = action.val;
            return stateQppSpouse;
        case SET_OAS_PENSION_YOU:
            const stateOasPensionYou = {...state};
            stateOasPensionYou.you.oasPension.val = action.val;
            return stateOasPensionYou;
        case SET_OAS_PENSION_SPOUSE:
            const stateOasPensionSpouse = {...state};
            stateOasPensionSpouse.spouse.oasPension.val = action.val;
            return stateOasPensionSpouse;
        case SET_EMPLOYER_PENSION_YOU:
            const stateEmployerPensionYou = {...state};
            stateEmployerPensionYou.you.employerPension.val = action.val;
            return stateEmployerPensionYou;
        case SET_EMPLOYER_PENSION_SPOUSE:
            const stateEmployerPensionSpouse = {...state};
            stateEmployerPensionSpouse.spouse.employerPension.val = action.val;
            return stateEmployerPensionSpouse;
        case SET_OTHER_YOU:
            const stateOtherYou = {...state};
            stateOtherYou.you.other.val = action.val;
            return stateOtherYou;
        case SET_OTHER_SPOUSE:
            const stateOtherSpouse = {...state};
            stateOtherSpouse.spouse.other.val = action.val;
            return stateOtherSpouse;
        case SET_FOURTH_STEP_COMPLETED:
            return {
                ...state,
                fourthStepCompleted: action.val
            }
        default:
            return state
    }
}