import React from 'react';
import './App.module.scss';
import {createStore, compose, applyMiddleware} from 'redux';
import { Provider } from 'react-redux';
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'
import Routes from "./Routes/Routes";

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                                                        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
                                                    }) : compose;


const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

function App() {
  return (
      <Provider store={store}>
          <ErrorBoundary>
          <Routes/>
          </ErrorBoundary>
      </Provider>
  );
}

export default App;
