export default {
    you: {
        rrsp: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
        },
        nonRegistered: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
        },
        workplaceSavings: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
            legislationType: null
        },
        tfsa: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
        },
        lockedIn: {
            exist: false,
            total: 0,
            legislationType: null
        },
        inheritance: {
            exist: false,
            total: 0,
            inheritanceYear: null
        }
    },
    spouse: {
        rrsp: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
        },
        nonRegistered: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
        },
        workplaceSavings: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
            legislationType: null
        },
        tfsa: {
            exist: false,
            total: 0,
            contributeMonthly: 0,
        },
        lockedIn: {
            exist: false,
            total: 0,
            legislationType: null
        },
        inheritance: {
            exist: false,
            total: 0,
            inheritanceYear: null
        }
    },
    properties: {
        exist: false,
        isCottage: false,
        cottages: [],
        isRentalProperties: false,
        rentalProperties: [],
        home:[]
    },
    thirdStepCompleted: false
}