import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';

WebFont.load({
               google: {
                 families: ['Montserrat:700;800;900', 'sans-serif']
               }
             });

const target = document.getElementById('ndg-root');

if (target) {
  ReactDOM.render(
      <React.StrictMode>
    <App />
  </React.StrictMode>,
      target
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
