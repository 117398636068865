import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loading from "../components/UI/Loading/Loading";
import classes from "../App.module.scss";
import { connect } from "react-redux";
import {homeLink, retirementCalcLinks} from "./RoutesConst";


const Home = lazy(() => import("../pages/Home/Home"));
const AboutYou = lazy(() => import('../pages/Steps/Step1/AboutYou/AboutYou'));
const RetireAge = lazy(() => import('../pages/Steps/Step1/RetireAge/RetireAge'));
const YearsIncome = lazy(() => import('../pages/Steps/Step1/YearsIncome'));
const RentOwn = lazy(() => import('../pages/Steps/Step1/RentOwn'));
const AnticipatedIncome = lazy(() => import('../pages/Steps/Step1/AnticipatedIncome'));
const Inflation = lazy(() => import('../pages/Steps/Step1/Inflation'));
const AboutYourSammary = lazy(() => import('../pages/Steps/Step1/AboutYourSammary'));

const Step2 = lazy(() => import('../pages/Steps/Step2/Step2'));
const ShortTermLosses = lazy(() => import('../pages/Steps/Step2/shortTermLosses/shortTermLosses'));
const LongTermReturns = lazy(() => import('../pages/Steps/Step2/LongTermReturns'));
const RecoveryPeriod = lazy(() => import('../pages/Steps/Step2/RecoveryPeriod/RecoveryPeriod'));
const GuaranteedFee = lazy(() => import('../pages/Steps/Step2/GuaranteedFee'));
const GuaranteedPercentage = lazy(() => import('../pages/Steps/Step2/GuaranteedPercentage'));
const Profile = lazy(() => import('../pages/Steps/Step2/Profile'));
const RateOfReturnYou = lazy(() => import('../pages/Steps/Step2/RateOfReturnYou'));
const RateOfReturnSpouse = lazy(() => import('../pages/Steps/Step2/RateOfReturnSpouse'));
const InvestorProfileSummary = lazy(() => import('../pages/Steps/Step2/InvestorProfileSummary'));

const Step3 = lazy(() => import('../pages/Steps/Step3/Step3'));
const SavingsOrInvestmentsSelector = lazy(() => import('../pages/Steps/Step3/SavingsOrInvestmentsSelector'));

const Rrsp = lazy(() => import('../pages/Steps/Step3/Rrsp/Rrsp'));
const RrspContribute = lazy(() => import('../pages/Steps/Step3/Rrsp/RrspContribute'));

const WorkplaceSavingsHave = lazy(() => import('../pages/Steps/Step3/WorkplaceSavings/WorkplaceSavingsHave'));
const WorkplaceSavingsContribute = lazy(() => import('../pages/Steps/Step3/WorkplaceSavings/WorkplaceSavingsContribute'));
const WorkplaceSavingsLegislationType = lazy(() => import('../pages/Steps/Step3/WorkplaceSavings/WorkplaceSavingsLegislationType'));

const LockedInHave = lazy(() => import('../pages/Steps/Step3/LockedIn/LockedInHave'));
const LockedInLegislationType = lazy(() => import('../pages/Steps/Step3/LockedIn/LockedInLegislationType'));

const TfsaHave = lazy(() => import('../pages/Steps/Step3/Tfsa/TfsaHave'));
const TfsaСontribute = lazy(() => import('../pages/Steps/Step3/Tfsa/TfsaСontribute'));

const NonRegisteredHave = lazy(() => import('../pages/Steps/Step3/NonRegistered/NonRegisteredHave'));
const NonRegisteredContribute = lazy(() => import('../pages/Steps/Step3/NonRegistered/NonRegisteredContribute'));
const Properties = lazy(() => import('../pages/Steps/Step3/Properties/Properties'));
const CottageEquity = lazy(() => import('../pages/Steps/Step3/Properties/CottageEquity'));
const RentalEquity = lazy(() => import('../pages/Steps/Step3/Properties/RentalEquity'));

const Inheritance = lazy(() => import('../pages/Steps/Step3/Inheritance/Inheritance'));
const InheritanceAmount = lazy(() => import('../pages/Steps/Step3/Inheritance/InheritanceAmount'));
const InheritanceYear = lazy(() => import('../pages/Steps/Step3/Inheritance/InheritanceYear'));
const HomeEquity = lazy(() => import('../pages/Steps/Step3/Properties/HomeEquity'));
const SavingsAndInvestmentsSummary = lazy(() => import('../pages/Steps/Step3/SavingsAndInvestmentsSummary'));

const Step4 = lazy(() => import('../pages/Steps/Step4/Step4'));
const OtherSourcesSelector = lazy(() => import('../pages/Steps/Step4/OtherSourcesSelector'));
const Cpp = lazy(() => import('../pages/Steps/Step4/Cpp'));
const Qpp = lazy(() => import('../pages/Steps/Step4/Qpp'));
const OasPension = lazy(() => import('../pages/Steps/Step4/OasPension'));
const EmployerPensionAmount = lazy(() => import('../pages/Steps/Step4/EmployerPensionAmount'));
const Other = lazy(() => import('../pages/Steps/Step4/Other'));
const SourceOfIncomeSummary = lazy(() => import('../pages/Steps/Step4/SourceOfIncomeSummary'));
const RetirementResults = lazy(() => import('../pages/RetirementResults/RetirementResults'));


const Routes = (props) => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading/>}>
              <div className={classes.retirementCalc}>
                <Switch>
                  <Route path={homeLink} exact component={Home} />
                  <Route path={retirementCalcLinks.aboutYou} component={AboutYou} />
                  <Route path={retirementCalcLinks.retireAge} component={RetireAge} />
                  <Route path={retirementCalcLinks.yearsOfIncome} component={YearsIncome} />
                  <Route path={retirementCalcLinks.rentOrOwn} component={RentOwn} />
                  <Route path={retirementCalcLinks.anticipatedIncome} component={AnticipatedIncome} />
                  <Route path={retirementCalcLinks.inflation} component={Inflation} />
                  <Route path={retirementCalcLinks.aboutYouSummary} component={AboutYourSammary} />

                  <Route path={retirementCalcLinks.investorProfile} component={Step2} />
                  <Route path={retirementCalcLinks.shortTermLosses} component={ShortTermLosses} />
                  <Route path={retirementCalcLinks.longTermReturns} component={LongTermReturns} />
                  <Route path={retirementCalcLinks.recoveryPeriod} component={RecoveryPeriod} />
                  <Route path={retirementCalcLinks.guaranteedFee} component={GuaranteedFee} />
                  <Route path={retirementCalcLinks.guaranteedPercentage} component={GuaranteedPercentage} />
                  <Route path={retirementCalcLinks.profile} component={Profile} />
                  <Route path={retirementCalcLinks.rateOfReturnYou} component={RateOfReturnYou} />
                  <Route path={retirementCalcLinks.rateOfReturnSpouse} component={RateOfReturnSpouse} />
                  <Route path={retirementCalcLinks.investorProfileSummary} component={InvestorProfileSummary} />

                  <Route path={retirementCalcLinks.savingsAndInvestments} component={Step3} />
                  <Route path={retirementCalcLinks.savingsOrInvestmentsSelector} component={SavingsOrInvestmentsSelector} />

                  <Route path={retirementCalcLinks.rrspHave} component={Rrsp} />
                  <Route path={retirementCalcLinks.rrspContribute} component={RrspContribute} />

                  <Route path={retirementCalcLinks.workplaceSavingsHave} component={WorkplaceSavingsHave} />
                  <Route path={retirementCalcLinks.workplaceSavingsContribute} component={WorkplaceSavingsContribute} />
                  <Route path={retirementCalcLinks.workplaceSavingsLegislation} component={WorkplaceSavingsLegislationType} />

                  <Route path={retirementCalcLinks.lockedInHave} component={LockedInHave} />
                  <Route path={retirementCalcLinks.lockedInLegislation} component={LockedInLegislationType} />

                  <Route path={retirementCalcLinks.tfsaHave} component={TfsaHave} />
                  <Route path={retirementCalcLinks.tfsaContribute} component={TfsaСontribute} />

                  <Route path={retirementCalcLinks.nonRegisteredHave} component={NonRegisteredHave} />
                  <Route path={retirementCalcLinks.nonRegisteredContribute} component={NonRegisteredContribute} />

                  <Route path={retirementCalcLinks.properties} component={Properties} />
                  <Route path={retirementCalcLinks.homeEquity} component={HomeEquity} />
                  <Route path={retirementCalcLinks.cottageEquity} component={CottageEquity} />
                  <Route path={retirementCalcLinks.rentalEquity} component={RentalEquity} />

                  <Route path={retirementCalcLinks.inheritance} component={Inheritance} />
                  <Route path={retirementCalcLinks.inheritanceAmount} component={InheritanceAmount} />
                  <Route path={retirementCalcLinks.inheritanceYear} component={InheritanceYear} />

                  <Route path={retirementCalcLinks.savingsAndInvestmentsSummary} component={SavingsAndInvestmentsSummary} />

                  <Route path={retirementCalcLinks.sourceOfIncome} component={Step4} />
                  <Route path={retirementCalcLinks.otherSourcesSelector} component={OtherSourcesSelector} />
                  <Route path={retirementCalcLinks.cppAmount} component={Cpp} />
                  <Route path={retirementCalcLinks.qppAmount} component={Qpp} />
                  <Route path={retirementCalcLinks.oasPension} component={OasPension} />
                  <Route path={retirementCalcLinks.employerPensionAmount} component={EmployerPensionAmount} />
                  <Route path={retirementCalcLinks.otherAmount} component={Other} />
                  <Route path={retirementCalcLinks.sourceOfIncomeSummary} component={SourceOfIncomeSummary} />

                  <Route path={retirementCalcLinks.retirementResults} component={RetirementResults} />

                  <Redirect to={homeLink} />
                </Switch>
            </div>
          </Suspense>
        </BrowserRouter>
    )
};

function mapStateToProps(state) {
    return {
        iAm: state.aboutYou.iAm,
    }
}

export default connect(mapStateToProps, null)(Routes);